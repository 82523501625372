import {
  CompileShallowModuleMetadata,
  ParseTemplateOptions,
} from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RoutesRecognized } from "@angular/router";
import { getClippingParents } from "@fullcalendar/core";
import { Utils } from "app/helper/_utils";
import { AuthService } from "app/service/auth.service";
import { RestService } from "app/service/rest.service";
import { Collapse } from "bootstrap";
import { OrderModule } from "ngx-order-pipe";
import { type } from "os";
import { Children } from "preact/compat";
import { title } from "process";

export const BASE_ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "nc-bank",
    class: "",
  },
  {
    path: "/saliks",
    title: "Saliks",
    icon: "nc-bus-front-12",
    class: "",
    type: "link",
  },
  {
    path: "/distance",
    title: "Kilometer",
    icon: "nc-pin-3",
    class: "",
    type: "link",
  },
  // { path: '/bills', title: 'Fuel Bills', icon: 'nc-money-coins', class: '' },
  {
    path: "/events",
    title: "Events",
    icon: "nc-bell-55",
    class: "",
    type: "link",
  },
  // { path: '/announcements', title: 'Announcements', icon: 'nc-email-85', class: '' },
  { path: "/maps", title: "Map", icon: "nc-map-big", class: "", type: "link" },
  //{ path: '/trips', title: 'Trips', icon: 'nc-bullet-list-67', class: ''  ,type:'link' },
  //{ path: '/stops', title: 'Stops', icon: 'nc-time-alarm', class: ''  ,type:'link' },
  // { path: '/cars', title: 'Cars', icon: 'nc-delivery-fast', class: '' },
  // { path: '/material-requests', title: 'Materials', icon: 'nc-basket', class: '' },
  // { path: '/vacations', title: 'Vacations', icon: 'nc-send', class: '' },
  // { path: '/captains', title: 'Captains', icon: 'nc-circle-10', class: '' },
  // { path: '/accidents', title: 'Accidents', icon: 'nc-settings-gear-65', class: '' },
  // { path: '/icons', title: 'Icons', icon: 'nc-bus-front-12', class: '' },
  // { path: '/notifications', title: 'Notifications', icon: 'nc-bell-55', class: '' },
  // { path: '/maps', title: 'Map', icon: 'nc-map-big', class: '' },
  // { path: '/user', title: 'User Profile', icon: 'nc-single-02', class: '' },
  // { path: '/table', title: 'Table List', icon: 'nc-tile-56', class: '' },
  // { path: '/typography', title: 'Typography', icon: 'nc-caps-small', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO', icon: 'nc-spaceship', class: 'active-pro' },
];

export const Hr_Routes: RouteInfo[] = [
  {
    path: "/requests",
    title: "Request",
    collapse: "Req",
    type: "sub",
    icon: "fa fa-sticky-note-o",
    // class: '' ,
    children: [
      { path: "emp-services", title: "Service Requests", ab: "SA" },
      { path: "vacations", title: "Vacation", ab: "V" },
    ],
  },
  {
    path: "/hr-module",
    title: "Hr",
    type: "sub",
    //class:'',
    collapse: "Hr",
    icon: "nc-icon nc-layout-11",
    children: [
      { path: "employees", title: "Employees", ab: "EM" },
      { path: "departments", title: "Departments", ab: "D" },
      { path: "attendance", title: "Attendance", ab: "A" },

      { path: "tasks", title: "Tasks", ab: "T" },
      //{path: 'products', title: 'Products', ab:'p'},
      { path: "events", title: "Events", ab: "E" },
      { path: "payroll", title: "Payroll", ab: "PR" },
      { path: "recruitments", title: "Recruitments", ab: "R" },
      { path: "projects", title: "Projects", ab: "PRJ" },
      { path: "documents", title: "Documents", ab: "DOC" },
      { path: "contracts", title: "Contracts", ab: "Cons" },
    ],
  },
];
export interface RouteInfo {
  path: string;
  title: string;
  type?: string;
  icon: string;
  class?: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "DASHBOARD",
    icon: "nc-bank",
    class: "",
    type: "link",
  },
  //{ path: '/saliks', title: 'Saliks', icon: 'nc-bus-front-12', class: '' ,type:'link'},
  //{ path: '/distance', title: 'KM', icon: 'nc-pin-3', class: '' ,type:'link' },
  { path: "/maps", title: "MAP", icon: "nc-map-big", class: "", type: "link" },
  // {
  //   path: "/commmandcenter",
  //   title: "Commandcenter",
  //   icon: "nc-map-big",
  //   class: "",
  //   type: "link",
  // },
  { path: "/events", title: "Events", icon: "nc-bell-55", class: "", type: "" },
  //{ path: '/traffic-fines', title: 'Traffic Fines', icon: 'nc-camera-compact', class: '' ,type:'link'  },

  //{ path: '/driver-behaviour', title: 'Driver Behaviour', icon: 'nc-satisfied', class: ''  ,type:'link'},

  //{ path: '/trips', title: 'Trips', icon: 'nc-bullet-list-67', class: '' ,type:'link'  },
  //{ path: '/stops', title: 'Stops', icon: 'nc-time-alarm', class: ''  ,type:'link' },
  // { path: '/announcements', title: 'Messages', icon: 'nc-email-85', class: ''  ,type:'link' },
  // { path: '/material-requests', title: 'Materials', icon: 'nc-basket', class: ''  ,type:'link' },
  //{ path: '/vacations', title: 'Vacations', icon: 'nc-send', class: ''  ,type:'' },
  // { path: '/assets', title: 'Assets ', icon: 'nc-tile-56', class: ''  ,type:'link' },

  //{ path: '/maintenance', title: 'Maintenance', icon: 'nc-settings', class: '' ,type:''  },

  {
    path: "/fleet",
    title: "Fleet",
    icon: "fa fa-car",
    //class: '' ,
    type: "sub",
    collapse: "FL",
    children: [
      { path: "cars", title: "Cars", ab: "C" },
      { path: "captains", title: "Captains", ab: "CP" },
      { path: "attendance", title: "attendance", ab: "A" },
      { path: "driver-behaviour", title: "Driver Behaviour", ab: "DB" },
    ],
  },
  {
    path: "/traficfines",
    title: "Fines & Bills",
    icon: "fa fa-camera",
    //class: '' ,
    type: "sub",
    collapse: "Fn",
    children: [
      { path: "fines", title: "Fines", ab: "EF" },
      { path: "fine-analytics", title: "Fine Analytics", ab: "FA" },
      //{path:'traffic-fines',title:"Fines",ab:"F",},
      //{path:'trafficfines-evg',title:"Assigned Fines",ab:"AF",},
      { path: "bills", title: "Fuel", ab: "F" },
    ],
  },
  {
    path: "/reports",
    title: "Reports",
    icon: "fa fa-sticky-note-o",
    class: "",
    type: "sub",
    collapse: "rep",
    children: [
      { path: "saliks", title: "Tolls", ab: "S" },
      //{ path: 'trips', title: 'Trips',ab: 'T'},
      //{ path: 'stops', title: 'Stops',ab:'S'},
      //{ path: 'distance', title: 'KM',ab: 'D'},
      { path: "app-analytics", title: "App Analytics", ab: "A" },
    ],
  },

  // {
  //   path: '/requests',
  //   title: 'Requests',
  //   collapse: 'Req',
  //   type: 'sub',
  //   icon: 'fa fa-edit',
  //   // class: '' ,
  //   children:[

  //   ]

  // },
  //   {
  //     path: '/hr-module',
  //     title: 'Hr',
  //     type: 'sub',
  //     //class:'',
  //     collapse: 'Hr',
  //     icon: 'nc-icon nc-layout-11',
  //     children: [
  //         {path: 'employees', title: 'Employees', ab:'EM'},
  //         {path: 'departments', title: 'Departments', ab:'D'},
  //         {path: 'attendance', title: 'Attendance', ab:'A'},

  //         {path: 'emp-services', title: 'Service Requests', ab:'SA'},
  //         {path: 'vacations', title: 'Vacation', ab:'V'},
  //         //{path: 'tasks', title: 'Tasks', ab:'T'},
  //         //{path: 'products', title: 'Products', ab:'p'},
  //         //{path: 'events', title: 'Events', ab:'E'},
  //         {path: 'payroll', title: 'Payroll', ab:'PR'},
  //         {path: 'recruitments', title: 'Recruitments', ab:'R'},
  //         //{path: 'projects', title: 'Projects', ab:'PRJ'},
  //         {path: 'documents', title: 'Documents', ab:'DOC'},
  //         {path: 'contracts', title: 'Contracts', ab:'Cons'},

  //     ]
  // },

  {
    path: "/general",
    title: "General",
    icon: "fa fa-info",
    //class: '' ,
    type: "sub",
    collapse: "Gn",
    children: [
      { path: "assets", title: "Assets ", ab: "AS" },
      { path: "announcements", title: "Messages", ab: "AN" },
      { path: "material-requests", title: "Materials", ab: "M" },
    ],
  },

  {
    path: "/workshop",
    title: "Workshop",
    icon: "fa fa-wrench",
    //class: '' ,
    type: "sub",
    collapse: "ws",
    children: [
      { path: "accidents", title: "Accidents", ab: "A" },
      { path: "maintenance", title: "Maintenance", ab: "M" },
    ],
  },
  //{ path: 'attendance', title: 'Sessions', icon: 'nc-map-big', class: ''  ,type:'link' },
  {
    path: "/commandcenter",
    title: "Cars control",
    icon: "nc-touch-id",
    class: "",
    type: "link",
  },
  //  {path: '/transactions', title: 'transactions', icon: 'nc-money-coins', class: ''  ,type:'link'},
  {
    path: "/preferences",
    title: "preferences",
    icon: "nc-settings",
    class: "",
    type: "link",
  },
  // { path: '/icons', title: 'Icons', icon: 'nc-bus-front-12', class: '' },
  // { path: '/notifications', title: 'Notifications', icon: 'nc-bell-55', class: '' },
  // { path: '/maps', title: 'Map', icon: 'nc-map-big', class: '' },
  // { path: '/user', title: 'User Profile', icon: 'nc-single-02', class: '' },
  // { path: '/table', title: 'Table List', icon: 'nc-tile-56', class: '' },
  // { path: '/typography', title: 'Typography', icon: 'nc-caps-small', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO', icon: 'nc-spaceship', class: 'active-pro' },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  title = "";
  public menuItems: any[];
  permissions: any[];

  constructor(
    private utils: Utils,
    private titleService: Title,
    private rest: RestService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    //this.get_perrmission();

    // if (this.utils.checkUserType() === "kd") {
    //   this.title = "Khadamaty Admin";
    //   this.setTitle("Khadamaty Dashboard");
    //   this.menuItems = BASE_ROUTES.filter((menuItem) => menuItem);
    // }
    // if (localStorage.getItem("groups") === "hronly") {
    //   this.title = "STSC User";
    //   this.setTitle("Sharjah Transport Solutions HR Dashboard");
    //   this.menuItems = Hr_Routes.filter((menuItem) => menuItem);
    // } else {
    this.title = "STSC Admin";
    this.setTitle("Sharjah Transport Solutions Dashboard");
    // this.permissions = localStorage.getItem("permissions").split(",");
    //console.log(this.permissions);
    let newroutes = ROUTES;

    //  parents = [];
    //  this.permissions.forEach(permission=>{
    //    title = permission.split('|')[0];
    //    order = permission.split('|')[1];

    //    if(order[:-1]==0){
    //     parents.push({
    //       'title': title,
    //       'order': order
    //     })
    //    }

    //  });

    //  parenr.forEach(element => {
    //    parent_code = element['order'].split('.')[0];
    //    let childs  =[];
    //    this.permissions.forEach(permission=>{
    //      if(permission.split('|')[1][0] == parent_code){
    //        childs.push(permission.split('|')[0])
    //      }
    //    })
    //    element['childs'] = childs;
    //  });

    // for (let i = 0; i < this.permissions.length; i++) {
    //   let t = this.permissions[i].toLowerCase();

    //   // title = t.split('|')[0]
    //   // order = t.split('|')[1]

    //   // hr-module|2
    //   // employees|2.1

    //   ROUTES.forEach((element) => {
    //     if (t == element.path.replace("/", "")) {
    //       newroutes.push(element);
    //     }
    //   });
    // }
    // console.log(newroutes);
    newroutes.push({
      path: "/reports",
      title: "Analytics",
      icon: "nc-chart-pie-36",
      class: "",
      type: "link",
    });
    //newroutes.push(  { path: '/rentreport', title: 'Rent Report', icon: 'nc-settings', class: ''  ,type:'link' },   );
    newroutes.push({
      path: "/finance",
      title: "finance",
      icon: "fa fa-money",
      //class: '' ,
      type: "sub",
      collapse: "fs",
      children: [
        { path: "rentreport", title: "Rent Report", ab: "R" },
        { path: "transactions", title: "Transactions", ab: "T" },
        { path: "loandue", title: "Loan Due", ab: "RD" },
      ],
    });
    newroutes.push({
      path: "/preferences",
      title: "preferences",
      icon: "nc-settings",
      class: "",
      type: "link",
    });

    this.menuItems = newroutes;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
